@charset "UTF-8";
/* CSS Document */


$font01 : 'DIN','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','YuGothic', 'Yu Gothic','游ゴシック','メイリオ','Meiryo',sans-serif;
$pink:#d94667;

.grp.pt{
	padding-top: 70px;
}

/* !!------------------------------------ */
/* !! header */

#header{
	position: relative !important;
}

main#main{
	margin-top: 0 !important;
}

// 20181204
.menu-icon{
	display: none;
}
// 20181204



/* !!------------------------------------ */
/* !! MV */

.left_mainvs{
	float: none !important;
	position: relative !important;
	height: auto !important;
	margin-bottom: 0px;
	.cloud_icon{
		position: relative;
		top: 0;left: 0;
	}
	
}


.right_mainvs{
	float: none !important ;
	width: 640px !important;
	margin: 0 auto;
	position: relative;
	#mailForm{
		width: 100%;
		padding: 20px;
		border-radius: 24px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	}
	.check{
		width: 100%;
		border-radius: 24px;
		margin-bottom: 0;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
		li{
			width: auto;
			
			margin: 15px !important;
		}
	}
	
}

@media screen and (min-width: 768px) {	
	
	
	
	
	.left_mainvs{
		//width: 690px;
		width: 540px;
		
		h2{
			text-align: left !important;
			img{
				max-width: 100% !important;
			}
		}
		
		.cloud_icon{
			top: -19px;
			li{
				width: 31% !important;
			}

		}	
	}
	

	//!! - top_example
	.top_mainvs{
		position: relative;
	}
	.b04_book.top_example{
		transform: scale(1);
		transform-origin: top right;
		position: absolute !important;
		top: 40px;
		padding: 0 !important;
		right: 0;
		width: 540px !important;
		background: none !important;
		p.hd{
			text-align: center;
			background: $red;
			color: #fff;
			font-size: 24px !important;
			line-height: 40px;
			border-radius: 20px;
			margin-bottom: 0;
		}
		&:before{
			display: none;
		}
		.book_inner:before{
			display: none !important;
			padding: 0 !important;
		}
		.left_book{
			&:before{
				display: none;
			}
			width: 100% !important;
			padding: 0px !important;
			//background-color: rgba(255, 255, 255, 0.42);
			//box-shadow: 0 0 16px rgba(255, 255, 255, 1);
			background: none;
			.idx_baf{
				align-items: flex-start;
				justify-content: space-between !important;
				li{
					width: 50% !important;
					img{
						width: 100% !important;
						height: auto !important;
						max-width: 1000px;
					}
				}
				.idx_bf{
					margin: 0;
					width: 40% !important;
					font-size: 0;
					img{
						margin: 0;
					}
					&:after{
						left: calc(100% + 20px);
					}
					table{
						padding:8px 10px;
						
						background: #fff;
						display: block;
						white-space: nowrap;
						tbody{
							white-space: nowrap;
							display: block;
						}
						tr{
							display: block !important;
							white-space: nowrap !important;
							td{
								display: inline-block !important; 
								margin-right: 15px;
								last{
									margin-right: 0;
								}
							}
						}
					}
				}
			}
			.b04_priceList{
				margin-bottom: 12px !important;
				li{
					background: #fff;
					width: 170px;
					font-size: 14px;
					white-space: nowrap;
					br{
						display: none;
					}
					span{
						display: inline-block;
						font-size: 24px;
						line-height: 1;
						vertical-align:bottom;
					}
				}
				&:after{
					display: none;
				}
			}
			.book_notice{
				padding: 8px 0 !important;
				letter-spacing: .1em;
				background: none !important;
				img{
					width: 105% !important;
					height: auto !important;
					position: relative;
					display: block;
					top: -22px;
					left: -10px;
				}
				br{
					display: none;
				}
			}
		}
		
	}
}

/* !! form */


.box-top{
	
	background: -webkit-linear-gradient(rgba(206, 238, 134, 0),  #cced84 5%, #fff 10.01%);
	background: -ms-linear-gradient(rgba(206, 238, 134, 0), #cced84 1%, #cced84 5%, #fff 10.01%);
	
	padding-top: 40px;
	.right_mainvs{
		margin-bottom: 50px;
	}
	#mailForm, .check{
		//background: #fff !important;
		//box-shadow: 0 0px 8px #fff5d0;
	}
	.left_book{
		//background: #fff !important;
	}
}
#mailForm{
	padding-bottom: 0 !important;
	overflow: hidden;
}
.form-wrap{
	width: 100%;
 	padding: 0 0 70px 0;
	margin: 0 auto;
	background: #CCED84;
	&:before{
		background: #fff;
		content: "";
		display: block;
		height: 130px;
		background-image: url("../images/top_footer_bg.png");
		background-size:auto 130px ;
		margin-bottom: 70px;
	}
	&.bottom{
		
	}
	.inner{
		overflow: hidden;
		width: 640px;
		padding: 20px;border-radius: 24px;
		padding-bottom: 0;
		background: rgba(255, 255, 255, 0.7);
		#mailForm{
			overflow: visible;
		}
	}


}

div.hd_contact.pc {
  width: 640px !important;
  float: none;
  text-align: center;
  //padding-top: 20px !important	;
  display: flex !important;
  background: #E9F2F7;
  margin: 0 !important;
  border-top: 0 !important;
  margin-left: -20px !important;
  padding: 30px !important;
  .desc{
	  width: 50%;
	  padding-right: 20px;
	  text-align: justify;
	  line-height: 1.5;
	  img{
		  width: 100% !important;
		  height: auto !important;
		  position: relative;
		  //top: -10px;
	  }
  }
  > a.hd_phone {
    width: 50% !important;
    position: relative;
    top: 10px;
  }
  i {
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-family: "NotoSansCJKjp-Black";
    line-height: 45px;
    font-style: normal;
    border-radius: 10px;
    border: 1px solid #363636;
    display: block;
    margin-top: 5px;
  }
}


.intro_mailForm{
	white-space: nowrap;
	li{
		width: auto !important;
		margin: 0 !important;
	}
}
.intro_mailForm span{
	font-size: 18px;
	line-height: 32px;
	margin:0 10px;
}
.fmail_must{
	display: none !important;
}
dl.mailform{
	dd{
		position: relative;
	}
}
dl.mailform dd select{
	border-radius: 0;
}

.ph{
	position: absolute;
	line-height: 34px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	top: 0;left: 70px;
	pointer-events: none;
	&.off{
		display: none;
		pointer-events: none;
	}
}


#bpCheck {display:none;}
body.confirm{
	&:before{
		content: "";
		width: 100vw !important;
		height: 100vh !important;
		background: #000;
		position: fixed;
		top: 0;left:0; 
		z-index: 99;
		opacity: .8;
		
	}
	
	.right_mainvs{
		position: fixed;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%,-50%));
		z-index: 100;
		.check{
			display: none;
		}
	}
	
}//body.confirm


//!! - ボタン
dd.btn-wrap{
	position: relative;
	&:after{
		content: "→";
		font-size: 24px;
		font-weight: bold;
		color: #3AB549 !important;
		display: block;
		width: 40px;
		height: 40px;
		background: #fff;
		
		text-align: center;
		line-height: 40px;
		border-radius: 50%;
		position: absolute;
		z-index: 1;top: 50%;
		@include transform(translateY(-50%));
		left: 20px;
	}
}
.submit_confirm_button{
	margin: 15px 0;
	text-shadow: 2px 2px 2px #000;
	font-size: 30px;
	height: auto;
	background: #3AB549 !important;
	line-height: 70px;
	border: 3px solid #fff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.49);
}


//!! 加盟店募集
#mailformpro{
	width: 90%;
	margin: 0 auto;
	//background: #eee;
}
.partner_wrap{
	dt{
		position: relative;
		overflow: hidden;
		span{
			display: block;
			i{
				float: right;
				display: block;
				background: #FFA001;
				font-size: 12px;
				padding: 4px;
				color: #fff;
				font-style: normal;
			}
		}
	}
	dd{
		input[type=text],
		input[type=email],
		input[type=tel]{
			display: block;
			width: 50%;
			padding: 4px;
			font-size: 15px;
			&.add{
				width: 100% !important;
			}
		}
		textarea{
			display: block;
			width: 100% !important;
			height: 100px !important;
			padding: 4px;
			font-size: 15px;
		}
	}
	ol {
		font-size: 0;
		li {
			height: 36px;
			font-size: 15px;
			line-height: 36px;
			display: inline-block;
			margin-right: 8px;
			input {
				margin-right: 5px;
			}
			div.mfp_err  {
				position: absolute;
				bottom: 5px;
			}
		}
	}
}
.mfp_buttons,
.bt_wrap{
	margin: 20px 0;
	text-align: center;
	button{
		display: inline-block;
		width: 250px;
		margin: 0 10px;
	}
}

//!! - - 確認画面
#mfp_phase_confirm{
	h4{
		border: none;
	}
	table{
		border-collapse: collapse;
		table-layout: fixed;
		width: 100% !important;
		border: 1px solid  #ccc;
		th{
			border: none;
		}
		td{
			width: 100%;
			border: none;
		}
	}
	
}//#mfp_phase_confirm


#mfp_warning{
	display: none !important;
}


//!! 外壁塗装のお役立ち情報

//!! - archive
.wp-pagenavi{
	text-align: center;
	span,a{
		text-decoration: none;
		padding: 3px 13px;
		background: #FB8200;
		color: #fff;
		border-radius: 1px;
		display: inline-block;
		font-size: 13px;
		margin: 2px;
		&:hover{
			opacity: .7;
		}
		&.current{
			background: #FF9F28;
		}
	}
}


//!! - single
.information.single{
	.grp_left{
		img{
			max-width: 600px;
			height: auto;
		}
	}
}


//!! 施工事例
.case.archive{
	.list_case{
		p.img{
			height: 180px !important;
			img{
				height: auto !important;
			}
		}
	}
}

//!! - single

.case.single{
	.grp_txt{
		img{
			max-width: 100%;
			height: auto;
		}
	}
}

.f_btn01{
	display: block !important;
	opacity: 0;
	transition: 500ms;
}

#totop{
	opacity: 0;
	transition: 500ms;
}

body.move {
	.f_btn01,#totop{
		opacity: 1 !important;
	}
}


//!! - - 関連記事
ul.related_post{
	li{
		margin-bottom: 20px;
		position: relative;
		padding-left: 160px;
		min-height: 150px;
		a.wp_rp_thumbnail{
			position: absolute;
			top: 0;left: 0;
		}
		.wp_rp_title{
			font-size: 18px;
			font-weight: bold;
			display: block;
		}
		.wp_rp_excerpt{
			font-size: 13px;
			text-align: justify;
		}
	}
}

//!! 屋根サンクスページ
.table-wrap{
	margin-bottom: 30px;
	h3{
		width: 100% !important;
		font-size: 1.4rem;
		font-weight: bold;
		color: #000 !important;
		margin-bottom: 10px;
	}
	table{
		border: 1px solid black;
		border-collapse: collapse;
		table-layout: fixed;
		width: 100% !important;
		th{
			padding: 10px;
			background: #efefef;
			font-size: 1rem;
		}
		width: 100% !important;
		td{
			padding: 5px;
			text-align: center;
			//background: #efefef;
			font-size: 1rem;
		}
	}
	.note {
		display: block;
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 15px;
		}
	}
}
.tt{
	margin-bottom: 0;
}




/* !! フッター */
.top_footer{
	padding: 0;
	background: none !important;
	.box_intro{
		margin-top: 0 !important;
		&:first-of-type{
			margin-top: 60px !important;
		}
	}
}

/* !!------------------------------------ */
/* !! SP */



@media screen and (max-width: 639px) {
#bpCheck {display:block;}	



$px:0.27vw;


.logo img{
	width: 100% !important;
	height: auto !important;
}




/* !!------------------------------------ */
/* !! MV */

.left_mainvs{
	float: none !important;
	position: relative !important;
	height: auto !important;
	margin-bottom: 50px;
	.cloud_icon{
		position: relative;
		top: 0;left: 0;
	}
}


	.left_mainvs{
		//width: 690px;
		width: 100%;
		margin-bottom: 20px;
		h2{
			text-align: left !important;
			img{
				max-width: 100% !important;
			}
		}
		
		.cloud_icon{
			top: 0px;
			li{
				width: 32% !important;
			}

		}	
	}
	

	//!! - top_example
	.top_mainvs{
		position: relative;
	}
	
	
	.b04_book.top_example{
		transform: scale(1);
		transform-origin: top right;
		position: absolute !important;
		top: 40px;
		padding: 0 !important;
		right: 0;
		width: 100% !important;
		background: none !important;
		margin-bottom: 60px !important;
		p.hd{
			text-align: center;
			background: $red;
			color: #fff;
			font-size: 24px !important;
			line-height: 40px;
			border-radius: 20px;
			margin-bottom: 15px;
		}
		&:before{
			display: none;
		}
		.book_inner:before{
			display: none !important;
			padding: 0 !important;
		}
		.left_book{
			&:before{
				display: none;
			}
			width: 100% !important;
			padding: 0px !important;
			background-color: none !important;
			box-shadow: none !important;
			background: none !important;
			.idx_baf{
				align-items: flex-start;
				justify-content: space-between !important;
				li{
					width: 50% !important;
					order: inherit !important;
					img{
						width: 100% !important;
						height: auto !important;
						max-width: 1000px;
					}
				}
				.idx_bf{
					margin: 0;
					width: 40% !important;
					font-size: 0;
					img{
						margin: 0;
					}
					&:after{
						left: calc(100% + 20px);
					    content: "";
					    display: block;
					    position: absolute;
					    border-left: 13px solid #363636;
					    border-top: 16px solid transparent;
					    border-bottom: 16px solid transparent;
					    top: 50%;
					    -webkit-transform: translateY(-10%);
					    -moz-transform: translateY(-10%);
					    -ms-transform: translateY(-10%);
					    -o-transform: translateY(-10%);
					    transform: translateY(-10%);
					    left: calc(100% + 19px);
					}
					table{
						padding:8px 10px;
						
						background: #fff;
						display: table;
						white-space: normal;
						tbody{
							white-space: normal;
							display: table-row-group;
						}
						tr{
							display: table-row !important;
							white-space: normal !important;
							td{
								display: table-cell !important; 
								margin-right: 0;
								padding: 2px !important;
								font-size: 10px !important;
								last{
									margin-right: 0;
								}
							}
						}
					}
				}
			}
			.b04_priceList{
				margin-bottom: 12px !important;
				position: static !important;
				margin: 0 !important;
				margin-bottom: 15px !important;
				display: flex;
				width: 100% !important;
				li{
					background: #fff;
					width: calc( (100% - 20px) / 3 )!important;
					font-size: 14px;
					white-space: normal;
					height: auto !important;
					margin: 0 !important;
					padding: 12px 10px;
					&.special_price{
						//height: 50px;
						order: 0 !important;
						span:before{
							left: 50% !important;
							@include transform(translateX(-50%));
						}
					}
					br{
						display: none;
					}
					span{
						display: block;
						font-size: 24px;
						line-height: 1;
						vertical-align:bottom;
					}
				}
				&:after{
					display: none;
				}
			}
			.book_notice{
				padding: 0 !important;
				letter-spacing: .1em;
				background: none !important;
				br{
					display: none;
				}
			}
		}
		
	}


.b04_book.top_example{
	position: relative !important;
	background: none !important;
	top: 0;right: 0;
	width: auto !important;
	.book_inner{
		margin-bottom: 0 !important;
	}
	.left_book{
		background-color: rgba(255, 255, 255, 0.42);
		box-shadow: 0 0 16px rgba(255, 255, 255, 1);
	}
}

#content{
	padding-top: 0 !important;
}
.box-top{
	
	background: none !important;

	padding-top:0px;
	.right_mainvs{
		margin-bottom: 50px;
		padding: 20px;
		&:before{
			background: none !important;
		}
		&:after{
			display: none !important;
		}
	}
	#mailForm, .check{
		//background: #fff !important;
		//box-shadow: 0 0px 8px #fff5d0;
	}
	.left_book{
		//background: #fff !important;
	}
}


/* !! form */

.right_mainvs{
	float: none !important ;
	width: 100% !important;
	margin: 0 auto;
	position: relative;
	padding-bottom: 30px;
	#mailForm{
		width: 100%;
		padding: 20px;
		border-radius: 24px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	}
	.check{
		width: 100%;
		border-radius: 24px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
		li{
			width: 250px;
			margin: 15px !important;
		}
	}
	
}

.form-wrap{
	width: 100%;
	padding: 30px 10px;
	
	padding-top: 0;
	margin: 0 auto;
	&:before{
		height: 60px;
		width: 100vw;
		margin-left: -10px;
		background: url(../images/main_top03.png) repeat-x;
		background-size: 1020px;
		background-color: #fff !important;
		margin-bottom: 30px;
	}
	.inner{
		width: 100%;
		padding: 20px;border-radius: 24px;
		background: rgba(255, 255, 255, 0.7);
	}
}

//!! - ボタン
dd.btn-wrap{
	position: relative;
	&:after{
		font-size: 20px;
		font-weight: bold;
		color: #3AB549 !important;
		display: block;
		width: 30px;
		height: 30px;
		background: #fff;
		
		text-align: center;
		line-height: 30px;
		border-radius: 50%;
		position: absolute;
		z-index: 1;top: 50%;
		@include transform(translateY(-50%));
		left: 10px;
	}
}

.submit_confirm_button{
	margin: 15px 0;
	text-align: left;
	font-size: 20*$px !important;
	line-height: 50px;
	padding-left: 43px;
}

div.hd_contact.pc{
	display: none !important;
}

.intro_mailForm{
	li{
		font-size: 20px !important;
	}
}
.intro_mailForm span{
	font-size: 12px;
	line-height: 1.3;
	
	
}
.fmail_must{
	display: none !important;
}

dl.mailform dd select{
	border-radius: 0;
}

.ph{
	position: absolute;
	line-height: 34px;
	width: 100%;
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	top: 0;left:12%;
	text-align: left;
	pointer-events: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&.off{
		display: none;
		pointer-events: none;
	}
}





body.confirm{
	
	&:before{
		content: "";
		width: 100vw !important;
		height: 100vh !important;
		background: #000;
		position: fixed;
		top: 0;left:0; 
		z-index: 99;
		opacity: .8;
		
	}
	.right_mainvs{
		&:before,&:after{
			display: none;
		}
		width : calc(100vw - 30px);
		position: absolute;
		box-sizing: border-box;
		top: 0;
		left: 0;
		margin: 15px;
		@include transform(translate(0));
		z-index: 100;
		.check{
			display: none;
		}
	}
	
}//body.confirm
	

//!! 加盟店募集
#mailformpro{
	width: 90%;
	margin: 0 auto;
	//background: #eee;
}
.partner_wrap{
	dt{
		position: relative;
		overflow: hidden;
		margin-top: 10px;
		span{
			display: block;
			line-height:20px;
			font-size: 14px;
			position: relative;
			
			i{
				float: right;
				display: block;
				background: #FFA001;
				font-size: 12px;
				padding: 4px;
				color: #fff;
				line-height: 1;
				font-style: normal;
			}
		}
	}
	dd{
		padding: 15px !important;
		input[type=text],
		input[type=email],
		input[type=tel]{
			display: block;
			width: 100%;
			padding: 4px;
			font-size: 15px;
		}
		textarea{
			display: block;
			width: 100% !important;
			height: 100px !important;
			padding: 4px;
			font-size: 15px;
		}
	}
	ol {
		li {
			div.mfp_err  {
				right: 0;
				left: 0;
				text-align: center;
			}
		}
	}
}
.mfp_buttons,
.bt_wrap{
	margin: 20px 0;
	text-align: center;
	button{
		display: inline-block;
		width: 250px;
		margin: 0 10px;
		+button {
			margin-top: 15px;
		}
	}
}

//!! 屋根サンクスページ
.table-wrap{
	overflow: hidden;
	h3 {
		font-size: 1.2rem;
	}
	table {
		th,td {
			font-size: 0.8rem;
		}
	}
	.note {
		color: #000;
	}
}
&.tt {
	font-size: 12px;
	text-align: justify;
}


//!! フッターメニュー
.ft_nav{
	max-width: 100% !important;
	ul{
		li{
			display: block;
			text-align: left;
			border: 1px solid #ccc;
			border-width: 1px 0px 0px 0px;
			&:last-of-type{
				border-width: 1px 0px;
			}
			margin: 0;
			a{
				padding: 10px 0;
				display: block;
			}
		}
	}
}


//!! フッターバナー
.fixedContact{
	display: block;
	li{
		width: 100% !important;
		display: block;
		position: relative;
		padding: 10px 10px 10px 50px;
		color: #fff;
		a{
			padding: 10px !important;
			@include a-cover;
		}
		&:after{
			right: auto;
			top: 50%;
			@include transform(translateY(-50%));
			left: 10px;
		}
		&:last-of-type{
			display: none;
		}
		p{
			font-size: 10*$px;
			white-space: nowrap;
			margin-bottom: 5px;
			strong{
				font-size: 13*$px;
				font-weight: bold;
				display: block;
			}
		}
		.note{
			border: 1px solid #fff;
			font-size: 12px;
			text-align: center;
		}
	}
}
	
} //@media screen and (max-width: 767px)