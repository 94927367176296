$base:#947848;
$base-lite:#EFEBE4;
$blue:#00B9EE;
$pink:#d94667;
$text:#3f3b3a;
$gray-lite:#F8F6F2;
$line:#BDBAB9;
$gray:#333333;
$red:#df002d;
$p:4.7vw;

$ease1:  cubic-bezier(0, 0.74, 0.38, 0.96);

//@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300);
//@import url(https://fonts.googleapis.com/css?family=Hind:400,300);
//@import url(https://fonts.googleapis.com/css?family=Abel);
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600');

// @import url(../fonts/icomoon/style.css);

@mixin minchou{
	//font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
}

@mixin gothic{
	font-family:"Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important;
}


@mixin transition($arg){
	-webkit-transition:$arg;
	-moz-transition:$arg;
	-ms-transition:$arg;
	-o-transition:$arg;
	//filter:$arg;
}


@mixin filter($arg){
	-webkit-filter:$arg;
	-moz-filter:$arg;
	-ms-filter:$arg;
	-o-filter:$arg;
	filter:$arg;
}

@mixin transform($arg){
	-webkit-transform:$arg;
	-moz-transform:$arg;
	-ms-transform:$arg;
	-o-transform:$arg;
	transform:$arg;
}

@mixin brend($color){
	background-blend-mode: exclusion;
	background-color: $color ;
}

@mixin webfont($fw:600){
		//font-family: 'Ropa Sans', sans-serif;
		font-family: 'Roboto Slab', serif !important;
		font-weight: $fw;
	}

@mixin header-icon-shadow(){
	-webkit-filter:drop-shadow(rgba(0,0,0,0.8) 1px 1px 2px);
}

@mixin a-cover{
	position: absolute;
	display: block;
	z-index: 1;
	top: 0;
	left: 0;
	width:100%;
	height:100%;
}

@mixin centering{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin side-kakko{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid #666;
		border-right-width: 0;
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid #666;
		border-right-width: 0;
		height: 100%;
		width: 15px;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;right: 0;
		border: 1px solid #666;
		border-left-width: 0;
		height: 100%;
		width: 15px;
	}
}

@mixin bar($color){
	content: "";
	width: 50px;
	height: 5px;
	background:$color ;
	display:block;
	margin: 0 auto;
}


@mixin anchor($color:#000,$size:8px){
	display: block;
	position: absolute;
	left: 0;top: 10px;
	content: "";
	width: $size;height: $size;
	border: 1px solid  $color;
	border-width: 0 1px 1px 0;
	@include transform(rotate(-45deg));
	transform-origin: center center;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  } 
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

